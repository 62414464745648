<template>
  <v-timeline
    ref="timeline"
    align-top
    :class="[
      'cy-event-timeline pt-0',
      'cy-event-timeline--stacked',
      {
        'cy-event-timeline--no-tags': !showTags,
      },
    ]"
    dense
    v-bind="$attrs">
    <v-data-iterator
      ref="iterator"
      :items="events"
      v-bind="{ options, loading }"
      :class="iteratorClass"
      hide-default-footer
      @update:options="(options) => $emit('update:options', options)"
      @current-items="(items) => currentlyVisibleEvents = items">
      <template #item="{ item, index }">
        <!-- TODO: Remove :value workaround once it is no longer required for testing -->
        <v-lazy
          :options="lazyOptions"
          :min-height="showTags ? 51 : 21"
          :class="[
            'cy-event-timeline-lazy-item',
            {
              'cy-event-timeline-lazy-item--first-in-range': isItemFirstInRelativeTimeRange(item, index),
              'my-7': !showTags,
              'my-6': showTags,
            },
          ]"
          :value="index < 3 ? true : undefined">
          <v-hover>
            <CyEventsTimelineItem
              ref="item"
              :event="item"
              :show-tags="showTags"
              class="flex-wrap-reverse"
              :show-relative-timestamp="isItemFirstInRelativeTimeRange(item, index)">
              <template #tag="{ tag }">
                <slot
                  name="tag"
                  v-bind="{ tag }">
                  <CyTag
                    ref="tag"
                    :variant="tag.variant"
                    :is-selected="isTagSelected(tag)"
                    :label="tag.key"
                    element-type="button"
                    small
                    @click="$emit('tag-click', tag)">
                    {{ tag.content }}
                  </CyTag>
                </slot>
              </template>
              <template #actions="{ event }">
                <slot
                  name="actions"
                  :event="event"/>
              </template>
            </CyEventsTimelineItem>
          </v-hover>
        </v-lazy>
      </template>
      <template #footer>
        <slot name="pagination"/>
      </template>
    </v-data-iterator>
  </v-timeline>
</template>

<script>
import { mapActions } from 'vuex'
import CyEventsTimelineItem from '@/components/events/timeline-item.vue'

export default {
  name: 'CyEventsTimeline',
  components: {
    CyEventsTimelineItem,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    iteratorClass: {
      type: String,
      default: '',
    },
    lazyOptions: {
      type: Object,
      default: () => ({
        threshold: 0.25,
      }),
    },
    showTags: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    currentlyVisibleEvents: [],
  }),
  created () {
    this.FETCH_AVAILABLE({ keyPath: 'members' })
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    isTagSelected (tag) {
      return _.some(this.options.filters, (filterValue, filterKey) => {
        const filterKeyword = filterKey.split('[')[0]
        return filterValue.includes(tag.value) && filterKeyword === tag.key
      })
    },
    formatTimeAgo (timestamp) {
      return $date.$formatTimeAgo(timestamp)
    },
    isItemFirstInRelativeTimeRange (item, index) {
      const previousItem = this.currentlyVisibleEvents[index - 1]
      return this.formatTimeAgo(item.timestamp) !== this.formatTimeAgo(previousItem?.timestamp)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/tools/rtl";
@import "~vuetify/src/components/VTimeline/mixins";

$timeline-divider-width-dense: $timeline-inner-dot-small-size;

// https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VTimeline/VTimeline.sass
@mixin v-timeline(
  $timeline-divider-center: $timeline-divider-center,
  $timeline-divider-width: $timeline-divider-width,
  $timeline-item-padding: $timeline-item-padding,
  $timeline-line-width: $timeline-line-width,
) {
  // Elements
  .v-timeline {
    padding-top: $timeline-item-padding;

    &::before {
      width: $timeline-line-width;
    }
  }

  .v-timeline-item {
    padding-bottom: $timeline-item-padding;
  }

  ::v-deep .v-timeline-item__divider {
    min-width: $timeline-divider-width;
  }

  ::v-deep .v-timeline-item__opposite {
    max-width: calc(#{$timeline-divider-center} - #{$timeline-divider-width / 2});
  }

  // Before/after directions
  .v-timeline:not(.v-timeline--dense, .v-timeline--reverse) {
    .v-timeline-item {
      &:nth-child(odd):not(.v-timeline-item--before),
      &--after {
        ::v-deep .v-timeline-item__body { /* stylelint-disable-line no-descending-specificity */
          max-width: calc(#{100% - $timeline-divider-center} - #{$timeline-divider-width / 2});
        }
      }

      &:nth-child(even):not(.v-timeline-item--after),
      &--before {
        ::v-deep .v-timeline-item__body { /* stylelint-disable-line no-descending-specificity */
          max-width: calc(#{$timeline-divider-center} - #{$timeline-divider-width / 2});
        }
      }
    }
  }

  // Modifiers
  .v-timeline:not(.v-timeline--dense, .v-timeline--reverse) {
    @include timeline-line-align($timeline-divider-center, left, right);
  }

  .v-timeline--reverse:not(.v-timeline--dense) {
    @include timeline-line-align($timeline-divider-center, right, left);

    .v-timeline-item {
      &:nth-child(odd):not(.v-timeline-item--after),
      &--before {
        ::v-deep .v-timeline-item__body { /* stylelint-disable-line no-descending-specificity */
          max-width: calc(#{100% - $timeline-divider-center} - #{$timeline-divider-width / 2});
        }
      }

      &:nth-child(even):not(.v-timeline-item--before),
      &--after {
        ::v-deep .v-timeline-item__body { /* stylelint-disable-line no-descending-specificity */
          max-width: calc(#{$timeline-divider-center} - #{$timeline-divider-width / 2});
        }
      }
    }
  }
}

@include breakpoint("l") {
  @include v-timeline($timeline-divider-center: 4%);
}

@include breakpoint("xl") {
  @include v-timeline($timeline-divider-center: 4%);
}

.v-timeline--dense {
  &.v-timeline--reverse {
    @include timeline-line-align($timeline-divider-width-dense / 2, right, left);
  }

  &:not(.v-timeline--reverse) {
    @include timeline-line-align($timeline-divider-width-dense / 2, left, right);
  }

  ::v-deep .v-timeline-item__body {
    max-width: calc(100% - #{$timeline-divider-width-dense});
  }

  ::v-deep .v-timeline-item__divider {
    min-width: $timeline-divider-width-dense;
  }
}

.cy-event-timeline--stacked {
  .cy-event-timeline-item {
    ::v-deep .v-timeline-item__opposite {
      flex: 1 0 100%;
      max-width: 100%;
      margin-bottom: $spacer-4;
      padding-top: $spacer-2;
      padding-bottom: $spacer-2;
    }

    ::v-deep .v-timeline-item__body {
      padding-left: $spacer-4;
    }
  }
}

.cy-event-timeline-lazy-item:not(:first-child) {
  margin-top: $spacer-10;

  .cy-event-timeline--stacked & {
    margin-top: $spacer-6;

    &.cy-event-timeline-lazy-item--first-in-range {
      margin-top: $spacer-3;
    }
  }
}

.cy-event-timeline--no-tags {
  .cy-event-timeline-lazy-item:not(:first-child) {
    margin-top: $spacer-3;

    .cy-event-timeline--stacked & {
      margin-top: $spacer-3;

      &.cy-event-timeline-lazy-item--first-in-range {
        margin-top: $spacer-3;
      }
    }
  }
}
</style>
