var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',{class:[
    'cy-event-timeline-item pb-0',
    {
      'cy-event-timeline-item--force-show-opposite': _vm.forceShowOpposite,
      'has-actions': _vm.hasActionsSlot,
      'cy-event-timeline-item--critical': _vm.getEventSeverity(_vm.event) === 'critical',
      'cy-event-timeline-item--warning': _vm.getEventSeverity(_vm.event) === 'warning',
    } ],attrs:{"id":("event-timeline-item-" + (_vm.event.id)),"role":"listitem","aria-label":"Event","right":"","small":"","icon":_vm.getEventsIcon(_vm.event)},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [(_vm.showRelativeTimestamp)?_c('div',{class:[
        'cy-event-timestamp cy-event-timestamp--relative',
        { 'line-height-md': _vm.forceShowOpposite } ],attrs:{"data-cy":"timestamp-relative"}},[_vm._v(" "+_vm._s(_vm.formatTimeAgo(_vm.event.timestamp))+" ")]):_vm._e()]},proxy:true}])},[(_vm.event.type === 'Cycloid')?_c('div',{staticClass:"cy-event-message"},[_c('div',{staticClass:"cy-event-message__wrap"},[_c('div',{staticClass:"cy-event-message__avatar"},[(_vm.eventUser)?_c('CyAvatar',{attrs:{"item":_vm.eventUser,"sm":""}}):_vm._e()],1),_c('div',{staticClass:"cy-event-message__text"},[_c('div',{staticClass:"cy-event-message__title",domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.renderEventMessage(_vm.event)))}}),_c('div',{staticClass:"cy-event-timestamp"},[_vm._v(" "+_vm._s(_vm.formatTimestamp(_vm.event.timestamp))+" ")])])]),(_vm.event.notification && !_vm.event.notification.read_at)?_c('div',{staticClass:"cy-event__unread-dot",attrs:{"data-cy":"unread-dot"}}):_vm._e()]):[_c('div',{staticClass:"cy-event-heading"},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('div',{staticClass:"font-weight-bold pa-0"},[_vm._v(" "+_vm._s(_vm.event.title)+" ")]),_c('div',{staticClass:"cy-event-timestamp"},[_vm._v(" "+_vm._s(_vm.formatTimestamp(_vm.event.timestamp))+" ")])]),(_vm.event.notification && !_vm.event.notification.read_at)?_c('div',{staticClass:"cy-event__unread-dot mb-1",attrs:{"data-cy":"unread-dot"}}):_vm._e()]),_c('v-expansion-panels',{staticClass:"cy-event-details mt-1 pr-6"},[_c('v-expansion-panel',{staticClass:"elevation-0 rounded"},[_c('v-expansion-panel-header',{staticClass:"cy-event-details__header px-4 py-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var open = ref.open;
return [_c('div',{class:[{
                'text-truncate': !open,
                'py-3': open,
              }],domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.renderEventMessage(_vm.event, 'heading')))}})]}}])}),_c('v-expansion-panel-content',{staticClass:"cy-event-details__content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.renderEventMessage(_vm.event, 'body')))}})])],1)],1)],(_vm.showTags)?_c('v-card-text',{staticClass:"pa-0 mt-2"},[_c('CyTagList',{ref:"tags",attrs:{"tags":_vm.getEventTags(_vm.event),"contained":"","small":"","sort-key":""},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
              var tag = ref.tag;
return [_vm._t("tag",null,null,{ tag: tag })]}}],null,true)})],1):_vm._e(),(_vm.hasActionsSlot)?_c('CyMenu',{attrs:{"activator":("#event-timeline-item-" + (_vm.event.id) + " .v-timeline-item__dot"),"attach":("#event-timeline-item-" + (_vm.event.id) + " .v-timeline-item__divider"),"min-width":280},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("actions",null,{"event":_vm.event})]},proxy:true}],null,true)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }