var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline',_vm._b({ref:"timeline",class:[
    'cy-event-timeline pt-0',
    'cy-event-timeline--stacked',
    {
      'cy-event-timeline--no-tags': !_vm.showTags,
    } ],attrs:{"align-top":"","dense":""}},'v-timeline',_vm.$attrs,false),[_c('v-data-iterator',_vm._b({ref:"iterator",class:_vm.iteratorClass,attrs:{"items":_vm.events,"hide-default-footer":""},on:{"update:options":function (options) { return _vm.$emit('update:options', options); },"current-items":function (items) { return _vm.currentlyVisibleEvents = items; }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [_c('v-lazy',{class:[
          'cy-event-timeline-lazy-item',
          {
            'cy-event-timeline-lazy-item--first-in-range': _vm.isItemFirstInRelativeTimeRange(item, index),
            'my-7': !_vm.showTags,
            'my-6': _vm.showTags,
          } ],attrs:{"options":_vm.lazyOptions,"min-height":_vm.showTags ? 51 : 21,"value":index < 3 ? true : undefined}},[_c('v-hover',[_c('CyEventsTimelineItem',{ref:"item",staticClass:"flex-wrap-reverse",attrs:{"event":item,"show-tags":_vm.showTags,"show-relative-timestamp":_vm.isItemFirstInRelativeTimeRange(item, index)},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
        var tag = ref.tag;
return [_vm._t("tag",function(){return [_c('CyTag',{ref:"tag",attrs:{"variant":tag.variant,"is-selected":_vm.isTagSelected(tag),"label":tag.key,"element-type":"button","small":""},on:{"click":function($event){return _vm.$emit('tag-click', tag)}}},[_vm._v(" "+_vm._s(tag.content)+" ")])]},null,{ tag: tag })]}},{key:"actions",fn:function(ref){
        var event = ref.event;
return [_vm._t("actions",null,{"event":event})]}}],null,true)})],1)],1)]}},{key:"footer",fn:function(){return [_vm._t("pagination")]},proxy:true}],null,true)},'v-data-iterator',{ options: _vm.options, loading: _vm.loading },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }