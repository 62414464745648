<template>
  <v-timeline-item
    :id="`event-timeline-item-${event.id}`"
    role="listitem"
    aria-label="Event"
    right
    small
    :icon="getEventsIcon(event)"
    :class="[
      'cy-event-timeline-item pb-0',
      {
        'cy-event-timeline-item--force-show-opposite': forceShowOpposite,
        'has-actions': hasActionsSlot,
        'cy-event-timeline-item--critical': getEventSeverity(event) === 'critical',
        'cy-event-timeline-item--warning': getEventSeverity(event) === 'warning',
      },
    ]">
    <template #opposite>
      <div
        v-if="showRelativeTimestamp"
        :class="[
          'cy-event-timestamp cy-event-timestamp--relative',
          { 'line-height-md': forceShowOpposite },
        ]"
        data-cy="timestamp-relative">
        {{ formatTimeAgo(event.timestamp) }}
      </div>
    </template>
    <div
      v-if="event.type === 'Cycloid'"
      class="cy-event-message">
      <div class="cy-event-message__wrap">
        <div class="cy-event-message__avatar">
          <CyAvatar
            v-if="eventUser"
            :item="eventUser"
            sm/>
        </div>
        <div class="cy-event-message__text">
          <div
            class="cy-event-message__title"
            v-html="$sanitizeHtml(renderEventMessage(event))"/>
          <div class="cy-event-timestamp">
            {{ formatTimestamp(event.timestamp) }}
          </div>
        </div>
      </div>
      <div
        v-if="event.notification && !event.notification.read_at"
        data-cy="unread-dot"
        class="cy-event__unread-dot"/>
    </div>
    <template v-else>
      <div class="cy-event-heading">
        <div class="d-flex align-center flex-wrap">
          <div class="font-weight-bold pa-0">
            {{ event.title }}
          </div>
          <div class="cy-event-timestamp">
            {{ formatTimestamp(event.timestamp) }}
          </div>
        </div>
        <div
          v-if="event.notification && !event.notification.read_at"
          data-cy="unread-dot"
          class="cy-event__unread-dot mb-1"/>
      </div>
      <v-expansion-panels class="cy-event-details mt-1 pr-6">
        <v-expansion-panel class="elevation-0 rounded">
          <v-expansion-panel-header class="cy-event-details__header px-4 py-0">
            <template #default="{ open }">
              <div
                :class="[{
                  'text-truncate': !open,
                  'py-3': open,
                }]"
                v-html="$sanitizeHtml(renderEventMessage(event, 'heading'))"/>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="cy-event-details__content">
            <div v-html="$sanitizeHtml(renderEventMessage(event, 'body'))"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <v-card-text
      v-if="showTags"
      class="pa-0 mt-2">
      <CyTagList
        ref="tags"
        :tags="getEventTags(event)"
        contained
        small
        sort-key="">
        <template #tag="{ tag }">
          <slot
            name="tag"
            v-bind="{ tag }"/>
        </template>
      </CyTagList>
    </v-card-text>
    <CyMenu
      v-if="hasActionsSlot"
      :activator="`#event-timeline-item-${event.id} .v-timeline-item__dot`"
      :attach="`#event-timeline-item-${event.id} .v-timeline-item__divider`"
      :min-width="280">
      <template #default>
        <slot
          name="actions"
          :event="event"/>
      </template>
    </CyMenu>
  </v-timeline-item>
</template>

<script>
import { mapState } from 'vuex'
import CyTagList from '@/components/tag-list.vue'

export const icons = {
  abort: 'cancel',
  create: 'add',
  delete: 'delete',
  disable: 'power_settings_new',
  enable: 'power_settings_new',
  pause: 'pause',
  pin: 'push_pin',
  refresh: 'refresh',
  rerun: 'repeat_one',
  unpause: 'play_arrow',
  unpin: 'push_pin',
  update: 'edit',
  other: 'info',
}

export default {
  name: 'CyEventsTimelineItem',
  components: {
    CyTagList,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    forceShowOpposite: {
      type: Boolean,
      default: true,
    },
    showRelativeTimestamp: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('organization', {
      members: (state) => state.available.members,
    }),
    $static: () => ({
      severityColor: {
        err: 'error',
        crit: 'error',
        warn: 'warning',
        info: 'secondary',
      },
    }),
    eventUser () {
      const usernameTag = _.find(this.event.tags, ['key', 'user'])?.value
      if (!usernameTag) return null
      return _.find(this.members, { username: usernameTag }) || null
    },
    hasActionsSlot () {
      const ss = this.$scopedSlots
      const actionsNodes = ss?.actions?.({ event: this.event })
      return actionsNodes?.length
    },
  },
  methods: {
    formatTimeAgo (timestamp) {
      return $date.$formatTimeAgo(timestamp)
    },
    formatTimestamp (timestamp) {
      return $date.format(new Date(timestamp), 'h:mm a MMM d, yyyy')
    },
    getEventsIcon (event) {
      const eventType = _.get(_.find(event.tags, { key: 'action' }), 'value', 'other')
      return icons[eventType]
    },
    getEventTags ({ severity, type, tags }) {
      const sortedTags = _.sortBy(tags, ['key'])
      return _.transform(sortedTags, (result, tag) => {
        result.push({
          ...tag,
          variant: 'default',
          content: tag.value,
        })
      }, [
        {
          key: 'severity',
          value: severity,
          variant: this.$static.severityColor[severity],
          content: this.$t(`severities.${severity}`),
        },
        {
          key: 'type',
          value: type,
          variant: 'secondary',
          content: this.$t(`events.type.${type}`),
        },
      ])
    },
    renderEventMessage (event, part) {
      const isDeleteEvent = !!_.find(event.tags, { key: 'action', value: 'delete' })
      const sanitizedMessage = this.$sanitizeHtml(event.message)

      if (isDeleteEvent) {
        const parsedMessage = new DOMParser().parseFromString(sanitizedMessage, 'text/html')
        const deletedEntityLinkEl = parsedMessage.querySelectorAll('a')[1]
        const deletedEntityStaticEl = document.createElement('strong')
        deletedEntityStaticEl.textContent = deletedEntityLinkEl.textContent
        deletedEntityLinkEl.parentNode.replaceChild(deletedEntityStaticEl, deletedEntityLinkEl)
        return parsedMessage.body.innerHTML
      }

      if (part) {
        const htmlLineBreakAtStartOrEndRegex = /(?:^<br>+)|(?:<br>+$)/g
        const lineBreakRegex = /\r|<\/br>|<br\/>|\n/g
        const messageWithUniformLineBreaks = sanitizedMessage.replace(lineBreakRegex, '<br>')
        const messageHeadingAndBody = messageWithUniformLineBreaks.split(/<br>(.+)/)
        const trim = (string = '') => string.replace(htmlLineBreakAtStartOrEndRegex, '').trim()
        return {
          heading: trim(messageHeadingAndBody[0]),
          body: trim(messageHeadingAndBody[1]),
        }[part] || trim(messageWithUniformLineBreaks)
      }

      return sanitizedMessage
    },
    getEventSeverity ({ tags }) {
      return _.get(_.find(tags, { key: 'severity' }), 'value', 'info')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-timeline-item {
  color: cy-get-color("primary");
  font-size: $font-size-default;

  ::v-deep &__opposite {
    align-self: start;
  }

  ::v-deep &__dot {
    margin-top: 2px;
    transform: translate3d(0, 3px, 0);
  }

  ::v-deep &__inner-dot {
    outline: 4px solid map.get($grey, "light-4");
    background-color: cy-get-color("grey", "light-3") !important;

    .v-icon {
      color: cy-get-color("grey", "dark-3") !important;
    }
  }

  &.has-actions {
    ::v-deep .v-timeline-item__dot {
      cursor: pointer;
    }
  }

  &.cy-event-timeline-item--critical {
    ::v-deep .v-timeline-item__inner-dot {
      background-color: cy-get-color("error", "light-1") !important;

      .v-icon {
        color: cy-get-color("error", "dark-2") !important;
      }
    }
  }

  &.cy-event-timeline-item--warning {
    ::v-deep .v-timeline-item__inner-dot {
      background-color: cy-get-color("warning", "light-1") !important;

      .v-icon {
        color: cy-get-color("warning", "dark-3") !important;
      }
    }
  }
}

// Custom elements
.cy-event {
  &-timeline-item--force-show-opposite {
    ::v-deep .v-timeline-item__opposite {
      display: initial;
      z-index: 1;
      background-color: cy-get-color("white");
    }
  }

  &-timestamp {
    display: inline-block;
    color: cy-get-color("grey", "dark-2");
    font-size: 14px;

    &:not(.cy-event-timestamp--relative) {
      &::before {
        content: '•';
        margin-inline: 8px 4px;
      }
    }
  }

  &-message {
    display: flex;
    align-items: center;

    &__wrap,
    &__text,
    &__title {
      display: inline;
    }

    &__avatar {
      display: inline-block;
      vertical-align: middle;
    }

    ::v-deep .cy-avatar {
      height: 20px;
      margin-right: 8px;

      &__initials {
        font-size: 9px;
      }

      &__icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  &-details {
    .v-expansion-panel {
      border: 1px solid cy-get-color("grey", "light-2");
      background-color: cy-get-color("white");
      color: inherit;
    }

    .v-expansion-panel-header {
      min-height: 40px;
      font-size: inherit;
    }

    ::v-deep .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
      color: cy-get-color("grey", "dark-1");
    }

    ::v-deep .v-expansion-panel-content__wrap {
      padding-inline: 16px;
    }
  }

  &-message,
  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cy-event__unread-dot {
      width: 8px;
      height: 8px;
      margin-left: 8px;
      border-radius: 50%;
      background-color: cy-get-color("error");
    }
  }
}

::v-deep .tag-list__item:first-child {
  margin-left: 0;
}
</style>
